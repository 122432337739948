<template>
  <el-card shadow="never">
    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input
            v-model="ruleForm.title"
            :disabled="!(ruleForm.status==0||ruleForm.status==1||ruleForm.status==2)"
            autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="正文" prop="content">
        <div id="edtor"></div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="float: right" :disabled="ruleForm.status==3" @click="editArticle()">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import Axios from "axios";
import {baseURL} from '@/utils/ajax.js';

import wangeditor from "wangeditor";

export default {
  data() {
    return {
      editor: null,
      file: null,
      customerOptions: [],
      bigOptions: [],
      ruleForm: {
        id: 0,
        title: "",
        keyword: "",
        description: "",
        content: "",
        customerId: 0,
        typeId: 0,
        status: 0,
      },
    };
  },
  mounted() {
   
    const editor = new wangeditor("#edtor");
    editor.config.onchange = function (newHtml) {
      console.log("change 之后最新的 html", newHtml);
    };
      editor.config.uploadImgServer = baseURL+"/img/upload";
    editor.config.uploadFileName = "picture";
   
    (editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);
        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(baseURL.replace("/luyao","")+result.data.data[0]);
      },
    });
    editor.create();
    this.editor = editor;
      this.init();
    this.getBigTypeList();
    this.getCustomerList();
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    getCustomerList() {
      this.$api.get(
        "/customer/list",
        null,
        null,
        (successRes) => {
          this.customerOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    getBigTypeList() {
      this.$api.get(
        "/type/big/list",
        null,
        null,
        (successRes) => {
          this.bigOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    editArticle() {
      let context = this.editor.txt.html();
      this.$api.post(
        "/article/update",
        null,
        {
          content: context,
          title: this.ruleForm.title,
          id: this.ruleForm.id,
          keyword: this.ruleForm.keyword,
          description: this.ruleForm.description,
          customerId: this.ruleForm.customerId,
          typeId: this.ruleForm.typeId,
        },
        (successRes) => {
          if (successRes.status == 200) {
            this.$message("操作成功");
            this.$router.push({ name: "articleList" });
          } else {
            this.$message(successRes.message);
          }
        },
        (failureRes) => {
          this.$message("操作失败");
        }
      );
    },
    init() {
      const articleId = this.$route.query.id;
      this.getArticleById(articleId);
      console.log(baseURL);
    
    },
    getArticleById(id) {
      this.$api.get(
        "/article/getById",
        null,
        { id: id },
        (successRes) => {
          console.log(successRes);
          this.ruleForm = successRes.data;
          this.editor.txt.html(this.ruleForm.content);
          if (successRes.data.status == 3) {
            this.editor.disable();
          }
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
  },
};
</script>